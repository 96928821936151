import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button1 from "@mui/material/Button";
import axios from "axios";
import url from "../../url";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import swal from "sweetalert";
export default function EditTask({
  modal,
  toggle,
  updateTask,
  taskObj,
  taskId,
  fetchTasks,
}) {
  const [Assignee, setAssignee] = useState("");
  const [Task, setTaskname] = useState("");
  const [Description, setdescription] = useState("");
  const [Start_date, setStart_date] = useState("");
  const [Expect_date, setExpect_date] = useState("");

  const [formErrors, setFormErrors] = useState({
    Assignee: "",
    Task: "",
    Description: "",
    Start_date: "",
    Expect_date: "",
  });

  useEffect(() => {
    if (taskId) {
      setAssignee(taskObj.assignee);
      setTaskname(taskObj.name);
      setdescription(taskObj.description);
      setExpect_date(taskObj.expect_date);
      setStart_date(taskObj.start_date);
    }
  }, [taskId]);

  const assigneetext = (event) => {
    setAssignee(event.target.value);
  };

  const tasknametext = (event) => {
    setTaskname(event.target.value);
  };
  const desctext = (event) => {
    setdescription(event.target.value);
  };
  const StartDate = (event) => {
    setStart_date(event.target.value);
  };
  const ExpectDate = (event) => {
    setExpect_date(event.target.value);
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      Assignee: "",
      Task: "",
      Description: "",
      Start_date: "",
      Expect_date: "",
    };

    // Validate Assignee
    if (!Assignee.trim()) {
      newErrors.Assignee = "Assignee is required";
      valid = false;
    }

    // Validate Task Name
    if (!Task.trim()) {
      newErrors.Task = "Task Name is required";
      valid = false;
    }

    // Validate Description
    if (!Description.trim()) {
      newErrors.Description = "Description is required";
      valid = false;
    }

    // Validate Start Date
    if (!Start_date) {
      newErrors.Start_date = "Start Date is required";
      valid = false;
    }

    // Validate Expected Date
    if (!Expect_date) {
      newErrors.Expect_date = "Expected Date is required";
      valid = false;
    }

    setFormErrors(newErrors);
    return valid;
  };

  const handleUpdateTask = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      let tempObj = {
        id: taskId,
        assignee: Assignee,
        name: Task,
        description: Description,
        start_date: Start_date,
        expect_date: Expect_date,
      };

      axios
        .post(url.url + "/editTask.php", tempObj)
        .then((response) => {
          console.log("successfully updated");
          updateTask(tempObj); // Update the task in the list
          toggle();
          swal("Successfully Updated", "Task has been Updated!", "success");

          fetchTasks();
        })
        .catch((error) => {
          console.error("Error:", error);
          console.log(tempObj);
        });
    }
  };

  return (
    <>
      <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Update Task</ModalHeader>
          <ModalBody>
            <form action="">
              <div className="form-group ">
                <TextField
                  id="outlined-basic"
                  label="Assignee Name"
                  value={Assignee}
                  onChange={assigneetext}
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  required
                />
                <div
                  className="error-message"
                  style={{ color: "red", fontSize: "12px" }}
                >
                  {formErrors.Assignee}
                </div>
              </div>
              <div className="form-group mt-4">
                <TextField
                  id="outlined-basic"
                  label="Task Name"
                  value={Task}
                  onChange={tasknametext}
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  required
                />
                <div
                  className="error-message"
                  style={{ color: "red", fontSize: "12px" }}
                >
                  {formErrors.Task}
                </div>
              </div>
              <div className="form-group mt-4">
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={4}
                  fullWidth
                  color="secondary"
                  value={Description}
                  onChange={desctext}
                  required
                />
                <div
                  className="error-message"
                  style={{ color: "red", fontSize: "12px" }}
                >
                  {formErrors.Description}
                </div>
              </div>
              <div className="dates mt-3 mb-2 d-flex justify-content-center align-items-center">
                <div className="form-group">
                  <label htmlFor="">Starting Date</label>
                  <input
                    type="date"
                    name=""
                    id=""
                    value={Start_date}
                    onChange={StartDate}
                  />
                  <div
                    className="error-message"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {formErrors.Start_date}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="">Expected Date</label>
                  <input
                    type="date"
                    name=""
                    id=""
                    value={Expect_date}
                    onChange={ExpectDate}
                  />
                  <div
                    className="error-message"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {formErrors.Expect_date}
                  </div>
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button1
              variant="contained"
              className="mx-1"
              color="secondary"
              onClick={handleUpdateTask}
            >
              Update
            </Button1>
            <Button1
              variant="outlined"
              className="mx-1"
              color="secondary"
              onClick={toggle}
            >
              Cancel
            </Button1>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}
