import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import MailIcon from "@mui/icons-material/Mail";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import swal from "sweetalert";
import url from "../url";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://nedncl.com/">
        NCL | NED
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function Mailer() {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state

  const handleMailer = () => {
    setModalOpen(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isLoading) return; // Prevent multiple submissions while loading

    const sendData = {
      email: email,
      subject: subject,
      message: message,
    };

    setIsLoading(true); // Show loader on button click

    try {
      await axios.post(url.url + "/sendMail.php", sendData);
      console.log("successfully");
      swal("Successfully Sent", "Mail has been sent!", "success");
    } catch (error) {
      if (error.message === "Network Error") {
        console.log(error);
        swal({
          title: "Server not Connected!",
          text: "Please check your server or network settings.",
          icon: "error",
        });
      }
    } finally {
      setIsLoading(false); // Hide loader after completion (success or error)
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Box sx={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <MailIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Mailer
            </Typography>
            <Box component="form" onSubmit={handleSubmit} validate={true} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="username"
                label="EmailAddress"
                autoComplete="off"
                autoFocus
              />
              <TextField
                margin="normal"
                required={true}
                fullWidth
                name="subject"
                label="Subject"
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                id="subject"
                autoComplete="off"
              />
              <TextField
                margin="normal"
                required={true}
                fullWidth
                name="message"
                label="Message"
                type="text"
                multiline
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                id="message"
                autoComplete="off"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 1 }}
                disabled={isLoading} // Disable the button while loading
              >
                {isLoading ? <CircularProgress size={24} /> : "Send Mail"} {/* Show loader or "Send Mail" */}
              </Button>
            </Box>
          </Box>
          <Copyright sx={{ mt: 3, mb: 2 }} />
        </Box>
      </Container>
    </ThemeProvider>
  );
}
