import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { Formik, Form } from "formik";

import Basic_Info from "./Forms/Basic_Info";
import Documents from "./Forms/Documents";
import ReviewOrder from "./ReviewOrder";
import CheckoutSuccess from "./CheckoutSuccess";

import validationSchema from "./FormModel/validationSchema";
import checkoutFormModel from "./FormModel/checkoutFormModel";
import formInitialValues from "./FormModel/formInitialValues";
import useStyles from "./styles";
import url from "../../pages/url";
const steps = ["Info", "Preference", "Review"];
const { formId, formField } = checkoutFormModel;

function _renderStepContent(step) {
  switch (step) {
    case 0:
      return <Basic_Info formField={formField} />;
    case 1:
      return <Documents formField={formField} />;
    case 2:
      return <ReviewOrder />;
    default:
      return <div>Not Found</div>;
  }
}

export default function CheckoutPage() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  const currentDate = date + "/" + month + "/" + year;
  function _sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    await _sleep(3000);
    console.log(values);

    actions.setSubmitting(false);
    setActiveStep(activeStep + 1);
  }

  function _handleSubmit(values, actions, res) {
    if (isLastStep) {
      // _submitForm(values, actions);
      const jsonData = JSON.stringify({
        ...values,
        currentDate: currentDate, // Include the current date in the JSON data
      });
      
      // console.log(jsonData);
      // alert(jsonData)
      axios
        .post(url.url + "/insert.php", jsonData)
        .then((res) => {
          if (res.status === 201) {
            console.log(res);
            console.log(jsonData);
            _submitForm(values, actions);
          }
        })
        .catch((e) => {
          swal("Network Error!", "Type again!", "error");
          console.log("server not connected", e);
          // window.location.reload(true);
          actions.setSubmitting(false);
        });
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  return (
    <React.Fragment>
      <div
        style={{
          textAlign: "center",
          justifyContent: "center",
          marginBottom: "3%",
        }}
      >
        <img
          src={require("../../assets/Ncl_logo.png")}
          style={{
            width: "100px",
            height: "100px",
            marginLeft: "1%",
            // contain: "content",
            backgroundColor: "rgba(255,255,255,0.9)",
            // padding: "10px",
            borderRadius: "100%",
          }}
        />
      </div>
      <Typography component="h1" variant="h4" align="center">
        Internship Portal
      </Typography>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <React.Fragment>
        {activeStep === steps.length ? (
          <CheckoutSuccess />
        ) : (
          <Formik
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form id={formId}>
                {_renderStepContent(activeStep)}

                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={_handleBack} className={classes.button}>
                      Back
                    </Button>
                  )}
                  <div className={classes.wrapper}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      {isLastStep ? "Confirm" : "Next"}
                    </Button>
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </React.Fragment>
    </React.Fragment>
  );
}
