import * as React from "react";
import Avatar from "@mui/material/Avatar";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";

import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
// import Grid from '@mui/material/Grid';
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../../index1.css";
import swal from "sweetalert";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import url from "../url.js";
import { CircularProgress } from "@mui/material";
import Lottie from 'lottie-react';
import animationData from '../../assets/login.json';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://nedncl.com/">
        NCL|NED
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const theme = createTheme();
export default function SignInSide() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state

  const backToPortal = () => {
    // Navigate to the About page
    navigate("/");
  };

  const navigate = useNavigate();
  useEffect(() => {
    let login = localStorage.getItem("login");
    if (login) {
      navigate("/dashboard", { state: { role: role } });
    }
  });


  const submitForm = async (e) => {
    e.preventDefault();
    if (isLoading) return; // Prevent multiple submissions while loading

    const sendData = {
      email: email,
      password: password,
    };
    setIsLoading(true); // Show loader on button click

    try {
      const result = await axios.post(url.url + "/loginadmin.php", sendData);

      if (result.status === 202) {
        localStorage.setItem("login", true);
        localStorage.setItem("user", JSON.stringify(result.data));
        localStorage.setItem("name", JSON.stringify(sendData.email));
        navigate("/dashboard");
        swal("Welcome", `Welcome!  ${email}  `, "success");
      } else {
        swal("Invalid Credentials!", "Type again!", "error");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      swal("Error", "An error occurred during login.", "error");
    } finally {
      setIsLoading(false); // Hide loader after completion (success or error)
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Typography
        component="h1"
        variant="h4"
        style={{
          margin: "auto",
          padding: "30px",
          textAlign: "center",
          fontFamily: " cursive ",
        }}
      >
        For Admin Use Only
      </Typography>

      <Grid container component="main" sx={{ height: "auto" }}>
        <CssBaseline />
        <Grid className="left" item xs="auto" sm={6} md={7} />

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={4}
          xl={4}
          component={Paper}
          elevation={12}
          square
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
             <Lottie
        animationData={animationData}
        autoplay={true}
        style={{ maxWidth: '100px', maxHeight: '100px' }} 
        loop={false}
      />

            <Typography component="h1" variant="h4">
              Sign in
            </Typography>
            <Box
              component="form"
              validate={true}
              onSubmit={submitForm}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                name="email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="username"
                label="Username"
                autoComplete="off"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                id="password"
                autoComplete="off"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={isLoading} // Disable the button while loading
              >
                {isLoading ? <CircularProgress size={24} /> : " Sign In"}{" "}
                {/* Show loader or "Send Mail" */}
              </Button>
              <Button
                onClick={backToPortal}
                fullWidth
                variant="black"
                sx={{ mt: 0, mb: 2 }}
              >
                Back To Portal
              </Button>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
