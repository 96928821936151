import React, { useState, useRef } from "react";
import "./certificate.css";
import { jsPDF } from "jspdf";
import certificate from "../../assets/NCL_Internship_Certificate.pdf.png";
function Certificate() {
  const certificateWrapper = useRef(null);
  const [name, setName] = useState("");
  const [dated, setDated] = useState("");
  const [S_dated, setS_Dated] = useState("");
  const [E_dated, setE_Dated] = useState("");
  const [domain, setDomain] = useState("");

  const downloadPdfDocument = () => {
    const pdf = new jsPDF({
      orientation: "landscape", // Set orientation to "landscape"
      unit: "mm",
      format: [210, 160], // Swap width and height for landscape
    });

    // Add image
    pdf.addImage(certificate, "jpg ", 10, 10, 190, 140); // Adjust coordinates and size

    // Add name
    pdf.setFontSize(12);
    pdf.text(95, 77, `${name}`);

    // Add date
    pdf.setFontSize(12);
    pdf.text(
      52,
      117,
      `${dated
        .split("-")
        .reverse()
        .join("-")}`
    );

    // Add date
    pdf.setFontSize(12);
    pdf.text(
      66,
      89,
      `${S_dated.split("-")
        .reverse()
        .join("-")}`
    );
    // Add date
    pdf.setFontSize(12);
    pdf.text(
      95,
      89,
      `${E_dated.split("-")
        .reverse()
        .join("-")}`
    );
    // Add name
    pdf.setFontSize(12);
    pdf.text(127, 89, `${domain}`);

    pdf.save(`${name}`);
  };

  return (
    <div className="App">
      <div className="Meta">
        <h1>NCL-NCAI Certificates</h1>
        <p></p>
        Please enter name
        <input
          type="text"
          placeholder="Please enter  name..."
          label="Please enter name."
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <p></p>
        Start Date
        <input
          type="date"
          value={S_dated}
          onChange={(e) => setS_Dated(e.target.value)}
        />
        <p></p>
        Emd Date
        <input
          type="date"
          value={E_dated}
          onChange={(e) => setE_Dated(e.target.value)}
        />
        <p></p>
        Please enter domain
        <input
          type="text"
          placeholder="Please enter Domain..."
          value={domain}
          onChange={(e) => setDomain(e.target.value)}
        />
        <p></p>
        Dated.
        <input
          type="date"
          value={dated}
          onChange={(e) => setDated(e.target.value)}
        />
        <button onClick={downloadPdfDocument}>Download</button>
      </div>

      <div id="downloadWrapper" ref={certificateWrapper}>
        <div id="certificateWrapper">
          <p>{name}</p>

          <img src={certificate} alt="Certificate" />

          <div id="certificateWrapper_dated">
            <p>
              {dated
                .split("-")
                .reverse()
                .join("-")}{" "}
            </p>
          </div>
          <div id="certificateWrapper_domain">
            <p>{domain}</p>
          </div>
          <div id="certificateWrapper_S_dated">
            <p>
              {S_dated.split("-")
                .reverse()
                .join("-")}{" "}
            </p>
          </div>
          <div id="certificateWrapper_E_dated">
            <p>
              {E_dated.split("-")
                .reverse()
                .join("-")}{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Certificate;
