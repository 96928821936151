import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import swal from "sweetalert";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import BasicModal_For_Admins_Dash from "../../modals/Modal_Dashboard_Admins";
import url from "../url";
import { CircularProgress } from "@mui/material";
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://nedncl.com/">
        NCL | NED
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [supervisor, setSupervisor] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state
  const handleAddUserClick = () => {
    setModalOpen(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const sendData = {
      email: email,
      password: password,
      role: role,
      supervisor:supervisor
    };
    console.log(sendData);
    setIsLoading(true); // Show loader on button click
    
    try {
      await axios.post(url.url + "/insertAdmin.php", sendData);
      console.log("successfully");
      swal("Successfully Added", "You clicked the button!", "success");
    } catch (error) {
      if (error.message === "Network Error") {
        console.log(error);
        swal({
          title: "Server not Connected!",
          text: "Please check your server or network settings.",
          icon: "error",
        });
      }
  }
  finally {
    setIsLoading(false); // Hide loader after completion (success or error)
  }
}

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
      <Box sx={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <AccountCircleIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Add Users
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            validate={true}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              name="email"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="username"
              label="Username"
              autoComplete="off"
              autoFocus
              sx={{ mb: 0 }} // Add margin-bottom for spacing
            />
            <TextField
              margin="normal"
              required={true}
              fullWidth
              name="password"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              autoComplete="off"
              sx={{ mb: 2 }} // Add margin-bottom for spacing
            />
            <FormControl fullWidth     sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Role</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={role}
                label="Role"
                onChange={(e) => setRole(e.target.value)}
              >
                <MenuItem value="Super Admin">Super Admin</MenuItem>
                <MenuItem value="Admin">Admin</MenuItem>
                <MenuItem value="local User (EL)">local User (EL)</MenuItem>
                <MenuItem value="local User (AI)">local User (AI)</MenuItem>
                <MenuItem value="local User (ROBO)">local User (ROBO)</MenuItem>
                <MenuItem value="local User (WEB)">local User (WEB)</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth    sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Supervisor</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={supervisor}
                label="Role"
                onChange={(e) => setSupervisor(e.target.value)}
              >
                <MenuItem value="DR FAHEEM">DR FAHEEM</MenuItem>
                <MenuItem value="DR ABUL HASAN">DR ABUL HASAN</MenuItem>
                <MenuItem value="DR MAJIDA KAZMI">DR MAJIDA KAZMI</MenuItem>
                <MenuItem value="DR HASHIM RAZA KHAN">DR HASHIM RAZA KHAN</MenuItem>
              </Select>
            </FormControl>
            <BasicModal_For_Admins_Dash
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              // handleAddUserClick={handleAddUserClick}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 1 }}
              disabled={isLoading} // Disable the button while loading
              >
                {isLoading ? <CircularProgress size={24} /> : "ADD"} {/* Show loader or "Send Mail" */}
 
            </Button>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 0, mb: 2 }}
              onClick={handleAddUserClick}
            >
              See Users
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 3, mb: 2 }} />
        </Box>
      </Container>
    </ThemeProvider>
  );
}
