import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputLabel,
  MenuItem,
  Select,
  Button,
} from "@material-ui/core";
import { PieChart, Pie, Legend, Tooltip, Cell } from "recharts";
import { Link } from "react-router-dom";
import MailIcon from "@mui/icons-material/Mail";
import SettingsIcon from "@material-ui/icons/Settings";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BasicModal_For_Admins from "../../modals/Modal_Admins.jsx";
import BasicModal_For_Projects from "../../modals/Modal_Projects";
import BasicModal_For_Mailer from "../../modals/Modal_Mailer.jsx";
import CopyToClipboard from "../copyToClipboard/Copy_To_Clipboard";
import url from "../url.js";
import Swal from "sweetalert2";
import Lottie from "lottie-react";
import animationData from "../../assets/interns.json";
import animationDataAdmin from "../../assets/Dashboard.json";
const useStyles = makeStyles((theme) => ({
  dashboardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    background: "#F4F4F4",
    color: "#000",
    drawer: {
      width: 250, // Adjust the width as needed
    },
  },
  adminContainer: {
    backgroundColor: "#4d4d4d",
    padding: theme.spacing(4),
    borderRadius: theme.spacing(1),
    width: "20%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    overflow: "auto",
  
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "-70%",
      position: "sticky",
      zIndex: 1,
    },
  },

  link: {
    textDecoration: "none",
    marginBottom: theme.spacing(1),
    color: "#fff",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    transition: "background-color 0.3s",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#000",
    },
    "& hr": {
      backgroundColor: "#fff",
    },
  },
  linkIcon: {
    marginRight: theme.spacing(1),
  },
  tableContainer: {
    overflowY: "auto",
    overflowX: "auto",
    backgroundColor: "#f5f5f5",
    width: "78%",
    height: "100%",
    overflow: "auto",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: theme.spacing(1),
    margin: theme.spacing(2),
    marginLeft: "21%",
    [theme.breakpoints.down("sm")]: {
      width: "200%",
      marginTop: "35%",
      marginLeft: "2%",
      height: "200%",
    },
  },

  detailsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(-2),
  },
  line: {
    borderTop: "3px solid #888",
    margin: "1rem 0",
  },
  disabledlink: {
    display: "none",
  },
}));

const Dashboard = () => {
  const classes = useStyles();
 
  const [flag, setFlag] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenProjects, setModalOpenProjects] = useState(false);
  const [modalOpenMailer, setModalOpenMailer] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("All");
  const [filterUni, setFilterUni] = useState("All");
  const [users, setUsers] = useState([]);
  const [students, setStudents] = useState([]);
  const [userCounter, setUserCounter] = useState(1); // Initialize the counter with 1
  const state_for_role = JSON.parse(localStorage.getItem("user"));
  const state_for_name = JSON.parse(localStorage.getItem("name"));
  const user_role = {
    role: state_for_role?.role || "",
    supervisor: state_for_role?.supervisor || "",
  };


// console.log(user_name.name)

  const handleAddUserClick = async () => {
    setModalOpen(true);
  };

  const handleAddProjectsClick = async () => {
    setModalOpenProjects(true);
  };

  const handleSendMail = async () => {
    setModalOpenMailer(true);
  };

  useEffect(() => {
    getdata();
  }, []);

  useEffect(() => {
    if (user_role.role === "Super Admin") {
      setFlag(false);
    } else {
      setFlag(true);
    }
  }, []);

  const navigate = useNavigate();
  const signout = () => {
    localStorage.removeItem("login");
    navigate("/forAdminUse");
    localStorage.removeItem("user");
  };

  const getdata = async () => {
    axios
      .post(url.url + "/roleAssignAPI.php", user_role)
      .then((result) => {
        setData(result.data);
        // console.log(result.data)
      })

      .catch((error) => console.log(error));
  };

  function filterData() {
    let filteredData = data;

    // Filter by statu    s
    if (filter !== "All") {
      filteredData = filteredData.filter((item) => item.status === filter);
    }

    // Filter by university
    if (filterUni !== "All") {
      if (filterUni === "others") {
        // Filter data to exclude universities explicitly mentioned
        filteredData = filteredData.filter(
          (item) =>
            ![
              "UIT",
              "IIEE",
              "FAST",
              "DUET",
              "NUST",
              "SSUET",
              "NEDUET",
            ].includes(item.university)
        );
      } else {
        filteredData = filteredData.filter(
          (item) => item.university === filterUni
        );
      }
    }

    return filteredData;
  }

  const handleStatusChange = (userId, newStatus) => {
    const updatedUsers = users.map((user) => {
      if (user.id === userId) {
        return { ...user, status: newStatus };
      } else {
        return user;
      }
    });
    setUsers(updatedUsers);

    fetch(url.url + `/statusUpdate.php?userId=${userId}&status=${newStatus}&hired=${state_for_name}`)
    .then(() => {
      // If the API call is successful, re-fetch the user data
      fetch(url.url + "/roleAssignAPI.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user_role),
      })
        .then((response) => response.json())
        .then((data) => {
          // This is where you can access and work with the updated data
          // console.log("Updated data:", data);
          setData(data);
        })
        .catch((error) => {
          console.log("Error fetching updated user data:", error);
        });
    })
    .catch((error) => {
      console.log("Error updating user status:", error);
    });
  }
  const deleteReq = async (studentId) => {
    let deleteData = {
      table: "student",
      id: studentId,
    };
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        const response = await axios.post(
          url.url + "/deleteApi.php",
          deleteData
        );
        console.log(response); // Handle response as needed
        if (response.status === 200) {
          const response2 = await fetch(url.url + "/roleAssignAPI.php", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(user_role),
          });
          const data = await response2.json();
          setData(data);
        } else {
          console.log("Error updating user status:", response.statusText);
        }

        // Update the students state after deletion
        setStudents(students.filter((student) => student.id !== studentId));

        Swal.fire("Deleted!", "Selected Row has been deleted.", "success");
        // Handle the confirmed action here
      } catch (error) {
        console.error("Error deleting student:", error);
        Swal.fire(
          "Error",
          "An error occurred while deleting the student.",
          "error"
        );
      }
    }
  };

  // Sample data for the pie chart
  const domains = data.map((user) => user.domain);
  const COLORS = ["#8884d8", "#82ca9d", "#ffc658","#A9A9A9", "#52c41a"]; // Define the colors for each domain
  const totalDomains = domains.length;
  const aiMlCount = domains.filter((domain) => domain === "AI & ML").length;
  const roboticsCount = domains.filter((domain) => domain === "ROBOTICS")
    .length;
  const webAppCount = domains.filter((domain) => domain === "WEB & APP").length;
  const allCount = domains.filter((domain) => domain === "ALL").length;
  const embeddedSystemsCount = domains.filter(
    (domain) => domain === "EMBEDDED SYSTEMS"
  ).length;
  const pieChartData = [
    { name: "AI & ML", value: (aiMlCount / totalDomains).toFixed(2) * 100 },
    {
      name: "ROBOTICS",
      value: (roboticsCount / totalDomains).toFixed(2) * 100,
    },
    { name: "WEB & APP", value: (webAppCount / totalDomains).toFixed(2) * 100 },
    { name: "ALL", value: (allCount / totalDomains).toFixed(2) * 100 },
    
    {
      name: "EMBEDDED SYSTEMS",
      value: (embeddedSystemsCount / totalDomains).toFixed(2) * 100,
    },
  ];

  const handleLinkHover = (event) => {
    event.target.classList.add(classes.linkHover);
  };

  const handleLinkLeave = (event) => {
    event.target.classList.remove(classes.linkHover);
  };

  return (
    <div className={classes.dashboardContainer}>
      <div className={classes.adminContainer}>
      
          <Lottie
            animationData={animationDataAdmin}
            loop={false}
            autoplay={true}
            style={{
              maxWidth: "50%",
              maxHeight: "50%",
              
            
            }} // Adjust animation size
          />
          <Typography
            variant="h3"
            style={{ marginBottom: "4rem", color: "white" }}
          >
            {user_role.role}
          </Typography>
  
        {/* Additional content for admin section */}
        {flag ? (
          <span className={classes.disabledlink}>Disabled Link</span>
        ) : (
          <Link
            // to="/page1"
            className={classes.link}
            onMouseEnter={handleLinkHover}
            onMouseLeave={handleLinkLeave}
            disabled={flag}
            onClick={handleAddUserClick}
          >
            <AccountCircleIcon className={classes.linkIcon} />
            Add Users
          </Link>
        )}
        <BasicModal_For_Admins
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          // handleAddUserClick={handleAddUserClick}
        />

        {flag ? (
          <span className={classes.disabledlink}>Disabled Link</span>
        ) : (
          <Link
            className={classes.link}
            onMouseEnter={handleLinkHover}
            onMouseLeave={handleLinkLeave}
            disabled={flag}
            onClick={handleAddProjectsClick}
          >
            <SettingsIcon className={classes.linkIcon} />
            Add Projects
          </Link>
        )}
        <BasicModal_For_Projects
          open={modalOpenProjects}
          onClose={() => setModalOpenProjects(false)}
          // handleAddUserClick={handleAddProjectsClick}
        />

        <Link
          to="/certificate"
          className={classes.link}
          onMouseEnter={handleLinkHover}
          onMouseLeave={handleLinkLeave}
        >
          <CardMembershipIcon className={classes.linkIcon} />
          Certificate
        </Link>

        <Link
          to="/todos"
          className={classes.link}
          onMouseEnter={handleLinkHover}
          onMouseLeave={handleLinkLeave}
        >
          <AssignmentIcon className={classes.linkIcon} />
          Tasks
        </Link>

        <Link
          className={classes.link}
          onMouseEnter={handleLinkHover}
          onMouseLeave={handleLinkLeave}
          disabled={flag}
          onClick={handleSendMail}
        >
          <MailIcon className={classes.linkIcon} />
          Mail
        </Link>

        <BasicModal_For_Mailer
          open={modalOpenMailer}
          onClose={() => setModalOpenMailer(false)}
          // handleAddUserClick={handleAddUserClick}
        />
        {/* )} */}
        <Link
          className={classes.link}
          onMouseEnter={handleLinkHover}
          onMouseLeave={handleLinkLeave}
          onClick={signout}
        >
          <LogoutIcon className={classes.linkIcon} />
          Log Out
        </Link>

        <div className={classes.pieChartContainer}>
          <PieChart width={350} height={250}>
            <Pie
              data={pieChartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={60}
              fill="#8884d8"
              label
            >
              {pieChartData.map((entry, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </div>
      </div>

      <div className={classes.tableContainer}>
        <div className={classes.detailsContainer}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Lottie
              animationData={animationData}
              loop={true}
              autoplay={true}
              style={{ maxWidth: "5%", maxHeight: "5%" }} // Adjust animation size
            />
            <Typography
              variant="h4"
              style={{
                fontWeight: "bold",
                color: "black",
                fontFamily: "cursive",
              }}
            >
              Interns Data
            </Typography>
          </div>

          <div
            style={{
              position: "relative",
              top: "-30px",
              marginLeft: "85%",
              fontWeight: "bold",
            }}
          >
            By Univerity
            <InputLabel
              sx={{ p: 1, ml: 1 }}
              htmlFor="status-filter"
            ></InputLabel>
            <Select
              label="Filter by status:"
              value={filterUni}
              onChange={(event) => setFilterUni(event.target.value)}
              sx={{
                backgroundColor: "LightGray",
                fontWeight: "bold",
                height: 40,
                ml: "right",
                mr: "15px",
              }}
            >
              <MenuItem value="All">All 🎓</MenuItem>
              <MenuItem value="UIT">UIT 🏫 </MenuItem>
              <MenuItem value="IIEE">IIEE 🏢 </MenuItem>
              <MenuItem value="FAST">FAST 🏛️ </MenuItem>
              <MenuItem value="DUET"> DUET 🏢 </MenuItem>
              <MenuItem value="NUST">NUST 🏛️ </MenuItem>
              <MenuItem value="SSUET">SSUET 🏢 </MenuItem>
              <MenuItem value="NEDUET">NEDUET 🏢 </MenuItem>
              <MenuItem value="others">OTHERS 🌐 </MenuItem>
            </Select>
          </div>

          <div
            style={{
              position: "relative",
              top: "-30px",
              marginLeft: "85%",
              fontWeight: "bold",
            }}
          >
            By Status
            <InputLabel
              sx={{ p: 1, ml: 1 }}
              htmlFor="status-filter"
            ></InputLabel>
            <Select
              label="Filter by status:"
              value={filter}
              onChange={(event) => setFilter(event.target.value)}
              sx={{
                backgroundColor: "LightGray",
                fontWeight: "bold",
                height: 40,
                ml: "right",
                mr: "10px",
              }}
            >
              <MenuItem value="All">All 🌍</MenuItem>
              <MenuItem value="Available">Available ✅</MenuItem>
              <MenuItem value="Interviewed">Interviewed 📋</MenuItem>
              <MenuItem value="Hired">Hired 💼</MenuItem>
              <MenuItem value="Not Recommended">Not Recommended 🚫</MenuItem>
              <MenuItem value="Completed">Completed 🎉</MenuItem>
            </Select>
          </div>

          <hr className={classes.line} />
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    fontFamily: "cursive",
                  }}
                >
                  Id
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    fontFamily: "cursive",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    fontFamily: "cursive",
                  }}
                >
                  Contact
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    fontFamily: "cursive",
                  }}
                >
                  Univerity/
                  <br></br>
                  Domain/
                  <br></br>
                  Semester
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    fontFamily: "cursive",
                  }}
                >
                  Domain/
                  <br></br>
                  Project
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    fontFamily: "cursive",
                  }}
                >
                  Duration
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    fontFamily: "cursive",
                  }}
                >
                  Linkedin
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    fontFamily: "cursive",
                  }}
                >
                  Resume
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    fontFamily: "cursive",
                  }}
                >
                  Supervisor
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    fontFamily: "cursive",
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    fontFamily: "cursive",
                  }}
                  
                >
                  Hired By
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    fontFamily: "cursive",
                  }}
                  
                >
                  Applied 
                </TableCell>
                {flag ? (
                  <span className={classes.disabledlink}>Disabled Link</span>
                ) : (
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                      fontFamily: "cursive",
                    }}
                  >
                    Remove
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {filterData().map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  // Disable the entire row if the status is "Hired" and the user is an admin
                  disabled={
                    user_role.role === "Super Admin" && row.status === "Hired"
                  }
                >
                  <TableCell>{userCounter + index}</TableCell>
                  <TableCell>
                    {row.firstName}
                    <br></br>
                    {row.lastName}{" "}
                  </TableCell>
                    <TableCell>
                      <CopyToClipboard email={row.email} />
                      <br></br>
                      {row.phone}
                    </TableCell>
                  <TableCell>
                    {row.university}
                    <br></br>
                    {row.department}
                    <br></br>
                    {row.semester} semester
                  </TableCell>
                  <TableCell>
                    {row.domain}
                    <br></br>
                    {row.project}
                  </TableCell>
                  <TableCell>{row.internshipPeriod} weeks</TableCell>
                  <TableCell>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={row.linkedin}
                    >
                      Link
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://nedncl.com/internship-portal/${row.image}`}
                    >
                      Resume
                    </a>
                  </TableCell>

                  <TableCell>{row.supervisor}</TableCell>
                  <TableCell>
                    <select
                      value={row.status}
                      onChange={(e) =>
                        handleStatusChange(row.id, e.target.value)
                      }
                      disabled={row.status === "Hired"}
                    >
                      <option value="Available">Available</option>
                      <option value="Interviewed">Interviewed</option>

                      {row.status === "Hired" ? (
                        <option value="Hired" disabled>
                          Hired (Disabled)
                        </option>
                      ) : (
                        <option value="Hired">Hired</option>
                      )}
                      <option value="Completed">Completed</option>
                      <option value="Not Recommended">Not Recommended</option>
                    </select>
                  </TableCell>
                  <TableCell>
                    {row.status === "Hired" ? `${row.hired}` : null}
                  </TableCell>
                  <TableCell>{row.date}</TableCell>
                  {flag ? (
                    <span className={classes.disabledlink}>Disabled Link</span>
                  ) : (
                    <TableCell>
                      <Button onClick={() => deleteReq(row.id)}>Delete</Button>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Dashboard;
