import * as React from 'react';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Project from '../pages/project/Project_Dashboard';
import { Button } from '@mui/material';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal_For_Projects_Dash(props) {
  const { open, onClose, handleAddUserClick } = props;

  const handleOpen = () => {
    handleAddUserClick(); // Call the handleAddUserClick function from the Dashboard component
    onClose(); // Close the modal after calling the function
  };
  const handleClose = () => onClose();

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Button component="h1" variant="h4" style={{ margin: "right", padding: "right" }} onClick={handleClose}>
            back
          </Button>
        <Typography component="h1" variant="h4" style={{ margin: "auto", padding: "auto", textAlign: "center", fontFamily: "'Titillium Web', 'Helvetica Neue', 'Helvetica', Arial, sans-serif" }}>
            Projects
          </Typography>
          <Project />
        </Box>
      </Modal>
    </div>
  );
}