import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/core/styles';
import { writeText } from 'clipboard-polyfill';
import CopyAllIcon from '@mui/icons-material/CopyAll';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  copyButton: {
    backgroundColor: 'white',
    color: 'black',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },
  copiedButton: {
    backgroundColor: 'white',
    color: 'black',
    '&:hover': {
      backgroundColor: '#f0f0f0;',
    },
  },
}));

const CopyToClipboard = ({ email }) => {
  const classes = useStyles();
  const [copied, setCopied] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const handleCopyClick = () => {
    writeText(email) // Copy the entire email
      .then(() => {
        setCopied(true);
        setShowMessage(true);

        // Hide the message after 2 seconds
        setTimeout(() => {
          setShowMessage(false);
          setCopied(false);
        }, 2000);
      })
      .catch((error) => {
        console.error('Copy to clipboard failed:', error);
      });
  };
  const iconSize = copied ? '25px' : '20px';

  // Extracting the username from the email
  const username = email.split('@')[0];

  return (
    <div className={classes.root}>
      <p>
  <a href={`mailto:${email}`}>{"email"}</a>
</p>

      <Button
        className={copied ? classes.copiedButton : classes.copyButton}
        onClick={handleCopyClick}
      >
        <CopyAllIcon style={{ fontSize: iconSize }} />
        {showMessage }
      </Button>
    </div>
  );
};

export default CopyToClipboard;
