import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MaterialLayout from './components/Layout/MaterialLayout';
import CheckoutPage from './components/CheckoutPage';
import SignInSide from './pages/loginPage/loginpage';
import Dashboard from './pages/mainDashboard/dashboard.js';
import Protected from './pages/protectedRoute/Protected';
import Page404 from './pages/notFoundPage/page404';
import Certificate from './pages/certificate/certificate';
import LoadingWrapper from './pages/loader/loardingWrapper';
import TodoList from "./pages/todoPage/Components/TodoList";
import 'bootstrap/dist/css/bootstrap.min.css'


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LoadingWrapper />}>
          <Route index element={<MaterialLayout><CheckoutPage /></MaterialLayout>} />
          <Route path="/dashboard" element={<Protected Component={Dashboard} />} />
          <Route path="/certificate" element={<Protected Component={Certificate} />} />
          <Route path="/todos" element={<Protected Component={TodoList} />}/>
          <Route path="/forAdminUse" element={<SignInSide />} />
          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
