import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  TableContainer,
  Container,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Box,
} from "@mui/material";
import { useEffect } from "react";
import axios from "axios";
import url from "../url";
import { Button } from "@material-ui/core";

const user_Dash = () => {
  const [val, setVal] = useState([]);
  const [users, setUsers] = useState([]);
  const [userCounter, setUserCounter] = useState(1); // Initialize the counter with 1
  const getdata = async () => {
    axios
      .get(url.url + "/getUsers.php")
      .then((result) => {
        console.log(result.data);
        setVal(result.data);
      })
      .catch((error) => console.log(error));
  };
  // console.log(getdata)
  useEffect(() => {
    getdata();
  }, []);
  const deleteReq = async (userId) => {
    let deleteData = {
      table: "user",
      id: userId,
    };

    try {
      const response = await axios.post(url.url + "/deleteApi.php", deleteData);
      console.log(response); // Handle response as needed
      if (response.status === 200) {
        fetch(url.url + "/getUsers.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => setVal(data));
      } else {
        console.log("Error updating user status:");
      }
    
      setUsers(users.filter((user) => user.id !== userId));
    } catch (error) {
      console.error("Error deleting student:", error);
    }
  };

  return (
    <Container maxwidth="100%">
      <Box sx={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
        <AppBar
          position="sticky"
          sx={{ bgcolor: "#424242", top: 0, zIndex: 1 }}
        >
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: "bold", fontFamily: "cursive" }}
            >
              {/* Dashboard */}
            </Typography>
          </Toolbar>
        </AppBar>
        <TableContainer
          className="container"
          sx={{ maxHeight: "auto" }}
          component={Paper}
          maxwidth="sm"
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: 20,
                    fontFamily: "cursive",
                  }}
                >
                  Id
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: 20,
                    fontFamily: "cursive",
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: 20,
                    fontFamily: "cursive",
                  }}
                >
                  Role
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: 20,
                    fontFamily: "cursive",
                  }}
                >
                  Supervisor
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: 20,
                    fontFamily: "cursive",
                  }}
                >
                  Remove
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {val.map((row,index) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                 <TableCell>{userCounter + index}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.role}</TableCell>
                  <TableCell>{row.supervisor}</TableCell>
                  <TableCell>
                    <Button onClick={() => deleteReq(row.id)}>Delete</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default user_Dash;
