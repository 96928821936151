import React from "react";
import { useFormikContext } from "formik";
import { Typography, Grid } from "@material-ui/core";
import ProductDetails from "./Projects";
import ShippingDetails from "./Basic_Info";
import PaymentDetails from "./Documents";

export default function ReviewOrder() {
  const { values: formValues } = useFormikContext();
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Projects Ongoing
      </Typography>
      <ProductDetails />
      <Grid
        container
        spacing={2}
        style={{
          backgroundColor: "RGBA(255,255,255,0.3)",
          borderRadius: 12,
          paddingBottom: "3%",
        }}
      >
        <ShippingDetails formValues={formValues} />
        <PaymentDetails formValues={formValues} />
      </Grid>
    </React.Fragment>
  );
}
