import React from "react";
import { Link, Typography } from "@material-ui/core/";

export default function Footer() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"© "}
      All Rights Reserved,{" "}
      <Link color="inherit" href="https://nedncl.com/">
        {" "}
        Neurocomputation Lab | 
      </Link>
      {`${` `}${new Date().getFullYear()}`}
    </Typography>
  );
}
