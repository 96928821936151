import React from 'react';
import { Grid, Typography,Link } from '@material-ui/core';
import { InputField, CheckboxField, SelectField } from '../../FormFields';
import {MultiSelectField} from '../../FormFields'
const departments = [
  {
    value: undefined, 
    label: 'None'
  },
  {
    value: 'CIS',
    label: 'CIS'
  },
  {
    value: 'CIVIL',
    label:'CIVIL'
  },
  {
    value: 'PHYSICS',
    label: 'PHYSICS'
  },
  
  {
    value: 'TELECOM',
    label: 'TELECOM'
  },
  {
    value: 'SOFTWARE',
    label: 'SOFTWARE'
  },
  {
    value: 'ELECTRICAL',
    label: 'ELECTRICAL'
  },
  
  {
    value: 'BIO-MEDICAL',
    label: 'BIO-MEDICAL'
  },
  {
    value: 'MECHANICAL',
    label: 'MECHANICAL'
  },
  {
    value: 'AUTOMOTIVE',
    label: 'AUTOMOTIVE'
  },
  {
    value: 'ELECTRONICS',
    label: 'ELECTRONICS'
  },
 
   {
    value: 'OTHERS',
    label: 'OTHERS'
  }
];

const domains = [
  {
    value: 'ALL',
    label: 'ALL'
  },
  {
    value: 'AI & ML',
    label: 'AI & ML' 
  },
  {
    value: 'ROBOTICS',
    label: 'ROBOTICS' 
  },
  {
    value: 'WEB & APP',
    label: 'WEB & APP'
  },
  
  {
    value: 'EMBEDDED SYSTEMS',
    label: 'EMBEDDED SYSTEMS'
  },
];
const universities = [
  {
    value: undefined,
    label: 'None'
  },
  {
    value: 'UIT',
    label: 'UIT'
  },
  {
    value: 'IIEE',
    label: 'IIEE'
  },
  {
    value: 'FAST',
    label: 'FAST'
  },
  
  {
    value: 'DUET',
    label: 'DUET' 
  },
  {
    value: 'NUST',
    label: 'NUST'
  },
 
  {
    value: 'SSUET',
    label: 'SSUET'
  },
  {
    value: 'NEDUET',
    label: 'NEDUET' 
  },
  {
    value: 'OTHERS',
    label: 'OTHERS' 
  },
  
];


export default function Basic_Info(props) {
  const {
    formField: {
      firstName,
      lastName,
      emailaddress,
      university,
      department,
      domain,
      mobile,
      aboveInformationIsCorrect
    }
  } = props;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Basic Information
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <InputField name={firstName.name} label={firstName.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={lastName.name} label={lastName.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField name={emailaddress.name}  label={emailaddress.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
        <SelectField name={university.name} label={university.label} data={universities} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={department.name}
            label={department.label}
            data={departments}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <SelectField
    name={domain.name}
    label={domain.label}
    data={domains}
    fullWidth
  />
        </Grid>
        <Grid item xs={12} >
          <InputField name={mobile.name} label={mobile.label} fullWidth />
        </Grid>
      
        <Grid item xs={12}>
          <CheckboxField
            name={aboveInformationIsCorrect.name}
            label={aboveInformationIsCorrect.label}
            

          />
          <Grid item xs={6}>
          <Link color="inherit" href={"https://nedncl.com/internship-portal/privacy-policy.pdf"} target="_blank">
          Click Here
      </Link>
      </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
