export default {
  formId: "checkoutForm",
  formField: {
    firstName: {
      name: "firstName",
      label: "First name*",
      requiredErrorMsg: "First name is required",
    },
    lastName: {
      name: "lastName",
      label: "Last name*",
      requiredErrorMsg: "Last name is required",
    },
    emailaddress: {
      name: "emailaddress",
      label: "Email Address*",
      requiredErrorMsg: "Email Address is required",
    },
    university: {
      name: "university",
      label: "University*",
      requiredErrorMsg: "University is required",
    },
    department: {
      name: "department",
      label: "Department*",
      requiredErrorMsg: "Department is required",
    },
    domain: {
      name: "domain",
      label: "Domain*",
      requiredErrorMsg: "Domain is required",
    },
    mobile: {
      name: "mobile",
      label: "Mobile No*",
      requiredErrorMsg: "Mobile No is required",
      invalidErrorMsg: "Mobile No is not valid (e.g. 0304-2302356)",
    },
    aboveInformationIsCorrect: {
      name: "aboveInformationIsCorrect",
      label: "Terms & Conditions ",
      requiredErrorMsg: "Please check this box to proceed",
    },
    project: {
      name: "project",
      label: "Projects*",
      requiredErrorMsg: "Project is required",
      invalidErrorMsg: "at most 500 words ",
    },
    semester: {
      name: "semester",
      label: "Semester*",
      requiredErrorMsg: "semester is required",
    },
    internship: {
      name: "internship",
      label: "Internship Period*",
      requiredErrorMsg: "Internship Period is required",
    },
      supervisor: {
        name: "supervisor",
        label: "Supervisors*",
        requiredErrorMsg: "Supervisors is required",
    },
    linkedin: {
      name: "linkedin",
      label: "Linkedin Account*",
      requiredErrorMsg: "linkedin account is required",
      invalidErrorMsg: "CVV is invalid (e.g. 357)",
    },
    image: {
      name: "image",
      label: "Please upload your resume:",
      requiredErrorMsg: "Resume is required",
      type: "image",
    },
  },
};
