import React from "react";
import { Typography } from "@material-ui/core";

function CheckoutSuccess() {
  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom style={{ textAlign: "center" }}>
        Thank you for applying.
      </Typography>
      <Typography variant="subtitle1" style={{ textAlign: "center" }}>
        You will receive a confirmation email soon.
      </Typography>
    </React.Fragment>
  );
}

export default CheckoutSuccess;
