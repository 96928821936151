import React, { useState, useEffect } from "react";
import { Typography, List, ListItem, ListItemText } from "@material-ui/core";
import useStyles from "./styles";
import url from "../../../pages/url";

function ProductDetails() {
  const [val, setVal] = useState([]);

  const getdata = async () => {
    fetch(url.url + "/getProjects.php", {
      method: "GET",
    })
      .then((response) => response.text())
      .then((res) => {
        setVal(JSON.parse(res));
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    getdata();
  }, []);
  const classes = useStyles();
  return (
    <List disablePadding>
      {val.map((product) => (
        <ListItem className={classes.listItem} key={product.name}>
          <ListItemText
            primary={product.name}
            secondary={product.description}
          />
          <Typography variant="body2">{product.vacancy} vacancy</Typography>
        </ListItem>
      ))}
    </List>
  );
}

export default ProductDetails;
