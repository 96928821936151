import React from 'react';
import Lottie from 'lottie-react';
import animationData from '../../assets/loadingAnimation1.json';

const Loading = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center', // Center horizontally
        alignItems: 'center', // Center vertically
        minHeight: '100vh', // Minimum viewport height
        backgroundColor: '#f0f0f0', // Background color for the loading screen
      }}
    >
      <Lottie
        animationData={animationData}
        loop={true}
        autoplay={true}
        style={{ maxWidth: '80%', maxHeight: '80%' }} // Adjust animation size
      />
    </div>
  );
};

export default Loading;
