import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default function Header() {
  const classes = useStyles();

  return (
    <AppBar position="absolute" color="default" className={classes.appBar}>
      <Toolbar>
      <img
          src={require('../../assets/Ncl_logo.png')}
          style={{ borderRadius: 10 ,backgroundColor: "rgba(255,255,255,0.9)",height:"50px", width:"50px"}}
          className={classes.logo} // Add a CSS class for the logo image
        />
        <Typography variant='h5' color='inherit' noWrap style={{padding:"20px"}} >
        <a href="https://nedncl.com/" style={{ color: 'inherit', textDecoration: 'none' }}>NCL-NEDUET</a>
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative'
  },
  logo: {
    width: '4%', // Set default width to 7%
    borderRadius: 10, // Your existing styles for the logo
    
    [theme.breakpoints.down('sm')]: { // Media query for mobile devices
      width: '15%', // Set width to 15% for mobile devices
      
    },
  },
}));