import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import swal from "sweetalert";
import BasicModal_For_Projects_Dash from "../../modals/Modal_Dashboard_Projects";
import url from "../url";
import { CircularProgress } from "@mui/material";
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://nedncl.com/">
        NCL | NED
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function Project() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [vacancy, setVacancy] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state

  const handleAddUserClick = () => {
    setModalOpen(true);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const sendData = {
      name: name,
      description: description,
      vacancy: vacancy,
    };
    console.log(sendData);
    setIsLoading(true); // Show loader on button click
    
    try {
      await axios.post(url.url + "/insertProjects.php", sendData);
      console.log("successfully");
      swal("Successfully Added", "Project has been added!", "success");
    } catch (error) {
      if (error.message === "Network Error") {
        console.log(error);
        swal({
          title: "Server not Connected!",
          text: "Please check your server or network settings.",
          icon: "error",
        });
      }
  }
      finally {
        setIsLoading(false); // Hide loader after completion (success or error)
      }
  };
  return (
    <ThemeProvider theme={theme}>
          
      <Container component="main" maxWidth="xs">
      <Box sx={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
    
        <CssBaseline />
        <Box
          sx={{
            marginTop: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <AssignmentIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Add a Project
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            Validate={true}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              name="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              id="name"
              label="Project Name"
              autoComplete="off"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="description"
              label="Description"
              type="text"
              value={description}
              multiline
              rows={4}
              onChange={(e) => setDescription(e.target.value)}
              id="Description"
              autoComplete="off"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="vacancy"
              type="number"
              value={vacancy}
              onChange={(e) => setVacancy(e.target.value)}
              id="vacancy"
              label="Vacancies Available"
              autoComplete="off"
              autoFocus
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 1 }}
              disabled={isLoading} // Disable the button while loading
              >
                {isLoading ? <CircularProgress size={24} /> : "Add"} {/* Show loader or "Send Mail" */}
 
            </Button>
            <Button
              onClick={handleAddUserClick}
              fullWidth
              variant="contained"
              sx={{ mt: 0, mb: 1 }}
            >
              See Projects
            </Button>
            <BasicModal_For_Projects_Dash
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              handleAddUserClick={handleAddUserClick}
            />
          </Box>
        </Box>
        <Copyright sx={{ mt: 2, mb: 1 }} />
        </Box>
      </Container>
    </ThemeProvider>
  );
}
