import React from "react";
import { Typography, Grid, Box } from "@material-ui/core";
import useStyles from "./styles";

function PaymentDetails(props) {
  const { formValues } = props;
  const classes = useStyles();
  const { firstName, lastName, emailaddress, mobile } = formValues;
  return (
    <Grid item xs={12} sm={6}>
      <Typography variant="h6" gutterBottom className={classes.title}>
        Your Information
      </Typography>
      <Typography gutterBottom>{`${firstName} ${lastName}`}</Typography>
      <Typography gutterBottom>{`${emailaddress}`}</Typography>
      <Typography gutterBottom>{`${mobile}`}</Typography>
    </Grid>
  );
}

export default PaymentDetails;
