import React from "react";
import { Field } from "formik";
import { Hidden } from "@material-ui/core";
// const [file,setFile]=useState('')
import "../../index.css"
const FileField = ({
  field,
  form: { touched, errors,setFieldValue },
  name,
  label,
  isError,
  ...props
}) => {
  return (
    <>
    
      <Field
        variant="inherit"
        name="uploader"
        title={label}
        type={"file"}
        Hidden
        id="myFile"
        onChange={(event)=>{
          let reader=new FileReader();
          let file = event.target.files[0];
          reader.onload=()=>{
            if(reader.readyState===2){
              // console.log(reader.result);
              // // setFile(file);
              // const formData=new FormData();
              // formData.append("image",reader.result)
              setFieldValue("image",reader.result)
              // // setPreview(reader.result)
              // console.log(formData)
            }
          }
          if (file) {
          reader.readAsDataURL(event.target.files[0])
          }
         }

        
        }
        style={{
          display: "flex",
          color: isError ? "red" : "var(--main-color)",
          BackgroundColor: "red"
        }}
        {...props}
      />
     
      {/* {isError && <FormHelperText>{errors[field.name]}</FormHelperText>} */}
    </>
  );
};

export default FileField;