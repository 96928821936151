import React, { useState, useEffect } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import Loading from './loadingPage';

const LoadingWrapper = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);

    // Simulate loading delay
    const delay = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(delay);
  }, [location]);

  // Exclude certain routes from showing loading screen
  const excludeLoadingRoutes = [ '/dashboard', // Replace with the actual modal route paths
]; // Add your modal routes here

  const shouldShowLoading = !excludeLoadingRoutes.some(route =>
    location.pathname.startsWith(route)
  );

  if (isLoading && shouldShowLoading) {
    return <Loading />;
  }

  return <Outlet />;
};

export default LoadingWrapper;
