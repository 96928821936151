import React from "react";
import { Grid, Typography, FormHelperText } from "@material-ui/core";
import { Field, useField } from "formik";
import { InputField, SelectField, FileField } from "../../FormFields";

const internships = [
  {
    value: "3 ",
    label: "3 weeks",
  },
  {
    value: "6 ",
    label: "6 weeks",
  },
  {
    value: "8 ",
    label: "8 weeks",
  },
  {
    value: "12 ",
    label: "12 weeks",
  },
  {
    value: "flexible",
    label: "flexible",
  },
  
];
const semesters = [
  {
    value: undefined,
    label: "None",
  },
  {
    value: "1st or 2nd",
    label: "1st or 2nd",
  },
  {
    value: "3rd or 4th",
    label: "3rd or 4th",
  },
  {
    value: "5th or 6th",
    label: "5th or 6th",
  },
  {
    value: "7th or 8th",
    label: "7th or 8th",
  },
  {
    value: "Graduated",
    label: "Graduated",
  },
];
const supervisors = [
  {
    value: "ANY",
    label: "ANY",
  },
  {
    value: "DR FAHEEM",
    label: "DR FAHEEM",
  },
  {
    value: "DR ABUL HASAN",
    label: "DR ABUL HASAN",
  },
  {
    value: "DR MAJIDA KAZMI",
    label: "DR MAJIDA KAZMI",
  },
  {
    value: "DR HASHIM RAZA KHAN",
    label: "DR HASHIM RAZA KHAN",
  },
];
export default function Documents(props) {
  const {
    formField: { project, semester, supervisor, internship, linkedin, image },
  } = props;
  const [field, meta] = useField(image.name);
  const { touched, error } = meta;
 

  const isError = touched && error && true;

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Preferences
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <InputField
            name={project.name}
            label="Projects You Have Made"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            name={semester.name}
            label={semester.label}
            data={semesters}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            name={internship.name}
            label={internship.label}
            data={internships}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            name={supervisor.name}
            label={supervisor.label}
            data={supervisors}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField name={linkedin.name} label={linkedin.label} fullWidth />
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid item xs={12}>
            <label
              style={{ color: `${isError ? "red" : "var(--main-color)"}` }}
            >
              {image.label}
            </label>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                fontSize: "1.2em",
              }}
            >
              <Field
                variant="outlined"
                field={field}
                component={FileField}
                isError={isError}
              />

              {isError && (
                <FormHelperText color={"red"}>{error}</FormHelperText>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
