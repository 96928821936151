import checkoutFormModel from "./checkoutFormModel";
const {
  formField: {
    firstName,
    lastName,
    emailaddress,
    university,
    department,
    domain,
    mobile,
    aboveInformationIsCorrect,
    project,
    semester,
    supervisor,
    internship,
    linkedin,
    image,
  },
} = checkoutFormModel;

export default {
  [firstName.name]: "",
  [lastName.name]: "",
  [emailaddress.name]: "",
  [university.name]: "",
  [department.name]: "",
  [domain.name]: "",
  [mobile.name]: "",
  [aboveInformationIsCorrect.name]: false,
  [project.name]: "",
  [semester.name]: "",
  [supervisor.name]:"",
  [internship.name]: "",
  [linkedin.name]: "",
  [image.name]: "",
};
