import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button1 from "@mui/material/Button";
import axios from "axios";
import url from "../../url";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import TaskList from "../Components/taskList"; // Import the TaskList component
import swal from "sweetalert";
import Swal from "sweetalert2";

export default function CreateTask({ modal, toggle, save }) {
  const [Assignee, setAssignee] = useState("");
  const [Task, setTaskname] = useState("");
  const [Description, setdescription] = useState("");
  const [Start_date, setStart_date] = useState("");
  const [Expect_date, setExpect_date] = useState("");
  const [taskList, setTaskList] = useState([]);

  const [formErrors, setFormErrors] = useState({
    Assignee: "",
    Task: "",
    Description: "",
    Start_date: "",
    Expect_date: "",
  });

  const fetchTasks = () => {
    axios
      .get(url.url + "/getTask.php") // Replace with your actual API endpoint
      .then((response) => {
        // Assuming the backend response includes an 'id' field
        const tasksWithId = response.data.map((task) => ({
          id: task.id,
          assignee: task.assignee,
          name: task.name,
          description: task.description,
          start_date: task.start_date,
          expect_date: task.expect_date,
        }));

        setTaskList(tasksWithId);
      })
      .catch((error) => {
        console.error("Error fetching tasks:", error);
      });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      Assignee: "",
      Task: "",
      Description: "",
      Start_date: "",
      Expect_date: "",
    };

    // Validate Assignee
    if (!Assignee.trim()) {
      newErrors.Assignee = "Assignee is required";
      valid = false;
    }

    // Validate Task Name
    if (!Task.trim()) {
      newErrors.Task = "Task Name is required";
      valid = false;
    }

    // Validate Description
    if (!Description.trim()) {
      newErrors.Description = "Description is required";
      valid = false;
    }

    // Validate Start Date
    if (!Start_date) {
      newErrors.Start_date = "Start Date is required";
      valid = false;
    }

    // Validate Expected Date
    if (!Expect_date) {
      newErrors.Expect_date = "Expected Date is required";
      valid = false;
    }

    setFormErrors(newErrors);
    return valid;
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  const handleTask = () => {
    if (validateForm()) {
      let taskObj = {
        assignee: Assignee,
        name: Task,
        description: Description,
        start_date: Start_date,
        expect_date: Expect_date,
      };

      axios
        .post(url.url + "/insertTask.php", taskObj)
        .then((result) => {
          toggle();
          swal("Successfully Added", "Task has been added!", "success");
          fetchTasks();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const assigneetext = (event) => {
    setAssignee(event.target.value);
  };

  const tasknametext = (event) => {
    setTaskname(event.target.value);
  };
  const desctext = (event) => {
    setdescription(event.target.value);
  };
  const StartDate = (event) => {
    setStart_date(event.target.value);
  };
  const ExpectDate = (event) => {
    setExpect_date(event.target.value);
  };

  const deleteTask = async (idToDelete) => {
    let deleteData = {
      table: "task",
      id: idToDelete,
    };
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        const response = await axios.post(
          url.url + "/deleteApi.php",
          deleteData
        );
        // Update the taskList state by filtering out the deleted task
        setTaskList((prevTaskList) =>
          prevTaskList.filter((task) => task.id !== idToDelete)
        );

        console.log("Task deleted successfully");
      } catch (error) {
        console.error("Error deleting task:", error);
      }
    }
  };

  const updateListArray = async (idToDelete) => {};

  return (
    <>
      <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Create Task</ModalHeader>
          <ModalBody>
            <form action="" validate={true}>
              <div className="form-group ">
                <TextField
                  id="outlined-basic"
                  label="Assignee Name"
                  value={Assignee}
                  onChange={assigneetext}
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  required
                />
                <div
                  className="error-message"
                  style={{ color: "red", fontSize: "12px" }}
                >
                  {formErrors.Assignee}
                </div>
              </div>
              <div className="form-group mt-4">
                <TextField
                  id="outlined-basic"
                  label="Task Name"
                  value={Task}
                  onChange={tasknametext}
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  required
                />
                <div
                  className="error-message"
                  style={{ color: "red", fontSize: "12px" }}
                >
                  {formErrors.Task}
                </div>
              </div>
              <div className="form-group mt-4">
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={4}
                  fullWidth
                  color="secondary"
                  value={Description}
                  onChange={desctext}
                  required
                />
                <div
                  className="error-message"
                  style={{ color: "red", fontSize: "12px" }}
                >
                  {formErrors.Description}
                </div>
              </div>
              <div className="dates mt-3 mb-2 d-flex justify-content-center align-items-center">
                <div className="form-group">
                  <label htmlFor="">Starting Date</label>
                  <input
                    type="date"
                    name=""
                    id=""
                    value={Start_date}
                    onChange={StartDate}
                    required
                  />
                  <div
                    className="error-message"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {formErrors.Start_date}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="">Expected Date</label>
                  <input
                    type="date"
                    className="Calendertype"
                    name=""
                    id=""
                    value={Expect_date}
                    onChange={ExpectDate}
                    required
                  />
                  <div
                    className="error-message"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {formErrors.Expect_date}
                  </div>
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button1
              variant="contained"
              className="mx-1"
              color="secondary"
              onClick={handleTask}
              type="submit"
            >
              Create
            </Button1>
            <Button1
              variant="outlined"
              className="mx-1"
              color="secondary"
              onClick={toggle}
            >
              Cancel
            </Button1>
          </ModalFooter>
        </Modal>
      </div>
      <TaskList
        taskList={taskList}
        deleteTask={deleteTask}
        updateListArray={updateListArray}
        fetchTasks={fetchTasks}
      />
    </>
  );
}
