import React from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText
} from '@material-ui/core';

function MultiSelectField(props) {
  const { label, data, ...rest } = props;
  const [field, meta] = useField(props);
  const { value: selectedValues } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;

  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  return (
    <FormControl {...rest} error={isError}>
      <InputLabel>{label}</InputLabel>
      <Select
        {...field}
        multiple
        value={selectedValues ? selectedValues : []}
        onChange={(event) => {
          const { value } = event.target;
          field.onChange({
            target: {
              name: field.name,
              value: value,
            },
          });
        }}
      >
        {data.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {_renderHelperText()}
    </FormControl>
  );
}

MultiSelectField.defaultProps = {
  data: [],
};

MultiSelectField.propTypes = {
  data: PropTypes.array.isRequired,
  // Add any other prop types you need
};

export default MultiSelectField;
