import React, { useState } from 'react';
import Card from './Card';

export default function TaskList({ taskList, deleteTask, updateListArray, fetchTasks }) {
  const [searchQuery, setSearchQuery] = useState('');

  // Function to filter tasks based on the search query
  const filteredTasks = taskList.filter((taskObj) =>
    taskObj.assignee.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="TaskContainer container mx-auto">
      {/* Display the search query as a Bootstrap Form Control */}
      <input
        type="text"
        className="form-control"
        placeholder="Search by Assignee Name"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      {filteredTasks.map((taskObj, index) => (
        <Card
          key={index}
          taskObj={taskObj}
          index={index}
          deleteTask={deleteTask}
          updateListArray={updateListArray}
          fetchTasks={fetchTasks}
        />
      ))}
    </div>
  );
}
