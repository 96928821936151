import * as Yup from "yup";
import checkoutFormModel from "./checkoutFormModel";
const {
  formField: {
    firstName,
    lastName,
    emailaddress,
    university,
    department,
    domain,
    mobile,
    project,
    semester,
    internship,
    linkedin,
    image,
    aboveInformationIsCorrect,
    supervisor
  },
} = checkoutFormModel;
// Define a custom Yup validation function
// Define a custom Yup validation function
const validateBase64Pdf = (message = 'File must be a PDF', maxSizeInMB = 5) => {
  return Yup.mixed().test('is-pdf', message, (value) => {
    if (!value) {
      return false; // Empty value is handled by required()
    }

    // Check if the Base64 string starts with the PDF data URI prefix
    if (!value.startsWith('data:application/pdf;base64,')) {
      return false;
    }

    // Remove the data URI prefix
    const base64WithoutPrefix = value.replace(/^data:application\/pdf;base64,/, '');

    // Calculate the size in bytes
    const fileSizeInBytes = (base64WithoutPrefix.length * 3) / 4;
    // Convert bytes to megabytes (MB)
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);

    // Compare the size to the maximum size
    return fileSizeInMB <= maxSizeInMB;
  });
};

const mobileNo =  /^0[1-9]\d{2}-\d{7}$/;
export default [

  
  Yup.object().shape({
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
    [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
    [emailaddress.name]: Yup.string()
      .email("Invalid email address")
      .required(`${emailaddress.requiredErrorMsg}`),
    [university.name]: Yup.string().required(`${university.requiredErrorMsg}`),
    [department.name]: Yup.string()
      .nullable()
      .required(`${department.requiredErrorMsg}`),
    [domain.name]: Yup.string()
      .nullable()
      .required(`${domain.requiredErrorMsg}`),
    [mobile.name]: Yup.string()
      .required(`${mobile.requiredErrorMsg}`)
      .matches(mobileNo, mobile.invalidErrorMsg),
    [aboveInformationIsCorrect.name]: Yup.boolean().oneOf(
      [true],
      aboveInformationIsCorrect.requiredErrorMsg
    ),
  }),
  Yup.object().shape({
    [project.name]: Yup.string()
    .nullable()
    .test(
      "len",
      `${project.invalidErrorMsg}`,
      (val) => !val || val.length <= 500
    )
    .default(null), // Ensure the default value is set to null if the field is not provided
    [semester.name]: Yup.string().required(`${semester.requiredErrorMsg}`),
    [supervisor.name]: Yup.string().required(`${supervisor.requiredErrorMsg}`),
    [internship.name]: Yup.string()
      .nullable()
      .required(`${internship.requiredErrorMsg}`),
    [linkedin.name]: Yup.string()
      .url("Invalid link")
      .required(`${linkedin.requiredErrorMsg}`),

    [image.name]:validateBase64Pdf(),
  
  }),
];
