import React, { useState } from 'react';
import EditTask from '../Modal/EditTask';
import './Card.css';
import Star from './Star';
import Dropdown from 'react-bootstrap/Dropdown';

export default function Card({ taskObj, index, deleteTask, updateListArray, fetchTasks }) {
  const [modal, setModal] = useState(false);

  const colors = [
    { primaryColor: "#0ff1ce" },
    { primaryColor: "#fc00b1" },
    { primaryColor: "#c0ff33" },
    { primaryColor: "#800869" },
    { primaryColor: "#fa7a55" },
    { primaryColor: "#b00bee" },
    { primaryColor: "#01d1e5" },
    { primaryColor: "#abbfab" }
  ];

  const handleDelete = () => {
    deleteTask(taskObj.id);
  };

  const toggle = () => {
    setModal(!modal);
  };

  const updateTask = () => {
    updateListArray(taskObj, index);
  };

  return (
    <>
      <div className="card shadow" style={{ borderTop: `0.35rem solid ${colors[index % 8].primaryColor}` }}>
        <div className="card-body">
          <div className='upper_part_card'>
            <div style={{ flexDirection: 'column' }}>
              <h5 className="card-title" style={{ fontSize: '18px', fontWeight: 'bold', color: '#333' }}>Assignee:</h5>
              <h7 className="card-title">{taskObj.assignee}</h7> {/* Assignee */}
              <h5 className="card-title" style={{ fontSize: '18px', fontWeight: 'bold', color: '#333' }}>Assigned To:</h5>
              <h7 className="card-title">{taskObj.name}</h7> {/* Name */}
            </div>
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#" onClick={() => setModal(true)}>
                  Edit
                </Dropdown.Item>
                <Dropdown.Item href="#" onClick={handleDelete}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <h5 className="card-title" style={{ fontSize: '18px', fontWeight: 'bold', color: '#333' }}>Description:</h5>
          <p className="card-text">{taskObj.description}</p>
          <div className='d-flex'>
            <div style={{ width: "9.4rem" }}>
              <p>Starting Date:</p>
            </div>
            <div>
              <p>{taskObj.start_date}</p>
            </div>
          </div>
          <div className='d-flex'>
            <div style={{ width: "9.4rem" }}>
              <p>Expecting Date:</p>
            </div>
            <div>
              <p>{taskObj.expect_date}</p>
            </div>
          </div>
          <div className='cards-icons '>
            <Star />
          </div>
        </div>
        <EditTask modal={modal} toggle={toggle} updateTask={updateTask} taskObj={taskObj} taskId={taskObj.id} fetchTasks={fetchTasks} />
      </div>
    </>
  );
}
